import { Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';
import { Picture } from 'types';
import { Swiper as SwiperClass } from 'swiper/types';
import useResponsive from 'features/responsive/responsive';
import { CarouselProps } from './Carousel';
import CarouselWithAds from './Carousel/CarouselWithAds';
import Img from './Img';

interface GalleryProps {
    pictures: Picture[];
    currentPicture?: Picture | undefined;
    setCurrentPicture?: Dispatch<SetStateAction<Picture | undefined>>;
    displayControls?: boolean;
    navButtonsOverlap?: boolean;
    isFullWithOnMobile?: boolean;
    breakpoints?: CarouselProps['breakpoints'];
    slidesPerView?: CarouselProps['slidesPerView'];
    pictureClass?: string;
}

export default function Gallery({
    pictures,
    currentPicture,
    setCurrentPicture = () => {},
    displayControls = false,
    navButtonsOverlap = false,
    isFullWithOnMobile = false,
    breakpoints,
    slidesPerView = 1,
    pictureClass,
}: PropsWithChildren<GalleryProps>) {
    const { IsMobile } = useResponsive();
    const [currentSlideNumber, setCurrentNumber] = useState<number>(1);
    const handleChange = (swiper: SwiperClass) => {
        setCurrentPicture(pictures[swiper.realIndex]);
    };

    useEffect(() => {
        const pictureIndex = pictures.findIndex((p) => p.id === currentPicture?.id);
        setCurrentNumber(pictureIndex + 1);
    }, [currentPicture]);

    useEffect(() => {
        window.location.hash = currentPicture?.id.toString() ?? '0';
    }, [currentPicture]);

    const handleInit = (swiper: SwiperClass) => {
        if (!currentPicture?.id) return;
        const pictureIndex = pictures.findIndex((p) => p.id === currentPicture?.id);
        swiper.slideToLoop(pictureIndex);
        setCurrentPicture(pictures[pictureIndex]);
    };

    return (
        <div className="flex flex-col justify-center h-full relative">
            <CarouselWithAds
                breakpoints={breakpoints}
                data={pictures}
                slidesPerView={slidesPerView}
                loop
                navButtonsOverlap={navButtonsOverlap}
                onInit={handleInit}
                onSlideChange={handleChange}
                isFullWithOnMobile={isFullWithOnMobile}
                autoHeight={false}
                adNativeClassName="h-[220px] overflow-hidden"
                isGallery
            >
                {(picture: any) => (
                    <div className={`rounded-xl md:!h-[500px] ${pictureClass || ''}`}>
                        <Img
                            alt="gallery"
                            src={picture.originalUrl}
                            className="swiper-zoom-container"
                        />
                    </div>
                )}
            </CarouselWithAds>
            <div className={`m-auto w-full text-center md:pt-[65.5px]  ${!IsMobile ? 'bottom-0 relative' : ''}`}>
                {currentSlideNumber} / {pictures.length}
            </div>
        </div>
    );
}
