import { useEffect, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper';
import { chevronDown } from 'ionicons/icons';
import { Swiper as SwiperClass } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/pagination';

import { useHistory, useParams } from 'react-router';
import TheoPlayerCustom from 'components/TheoplayerCustom';
import useReplayByCategory from 'features/podcastsReplays/hooks/useReplayByCategory';
import { PodcastOrReplay } from 'types';
import PageLayout from 'components/PageLayout';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { isNativeAd } from 'lib/utils';
import ShareButton from 'components/core/ShareButton';

const swiperSlideStyles = {
    color: 'white',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

interface Video {
    src: string;
    type: string;
}

export default function Eldo4Real() {
    const { podcastByCategory: data, isLoading } = useReplayByCategory('eldo4real');
    const history = useHistory();
    const { t } = useTranslation();

    const { id } = useParams<{ id?: any }>();

    const [currentVideo, setCurrentVideo] = useState<PodcastOrReplay>();

    const swiperRef = useRef<SwiperClass>();

    const replays = useMemo<PodcastOrReplay[]>(() => {
        if (Array.isArray(data)) {
            return data.filter((dataVideo) => !isNativeAd(dataVideo));
        }
        return (data?.replays || []).filter((dataVideo) => !isNativeAd(dataVideo)) as PodcastOrReplay[];
    }, [data]);

    const isEnd = useMemo(() => {
        if (replays == null) return false;
        // @ts-ignore
        return currentVideo?.src === replays[replays.length - 1]?.src;
    }, [currentVideo, replays]);

    useEffect(() => {
        if (replays && currentVideo == null && swiperRef.current != null) {
            // eslint-disable-next-line eqeqeq
            const indexReplaySelectByIdInURL = replays.findIndex((r) => r.id == id);
            if (indexReplaySelectByIdInURL !== -1) {
                swiperRef.current?.slideTo(indexReplaySelectByIdInURL);
                setCurrentVideo(replays[indexReplaySelectByIdInURL]);
            }
        }
    }, [replays]);

    const handleSlideChange = (swiper: any) => {
        const rep = replays[swiper.realIndex]; // Get the video corresponding to the current slide
        // @ts-ignore
        setCurrentVideo(rep);
    };

    const renderShareBtn = () => (
        <ShareButton
            url={currentVideo?.src}
            className="max-md:absolute max-md:right-2 max-md:-top-[25px]"
        />
    );

    const handleClickNextSlide = () => {
        if (!swiperRef.current) return;
        swiperRef.current.slideNext();
    };

    return (
        <PageLayout
            cssClass="text-white"
            title={t('eldo4real')}
            hasBackButton
            noPadding
        >
            <Swiper
                direction="vertical"
                slidesPerView={1}
                mousewheel
                pagination={{
                    clickable: true,
                }}
                noSwipingSelector=".vjs-control-bar"
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                onSlideChange={handleSlideChange}
                modules={[Mousewheel]}
                className="text-white h-full grid-fixed max-md:-mt-[25px]"
            >
                {(replays || []).map((replay, index) => {
                    return !isNativeAd(replay) ? (
                        <SwiperSlide
                            style={swiperSlideStyles}
                            key={`slider-${index}`}
                            className="m-0"
                        >
                            <div
                                className="h-[100%] w-[100%] relative"
                                key={`videoContainer-${index}`}
                            >
                                <div className="w-full h-full md:pb-blockInner m-auto relative">
                                    {replay.src === currentVideo?.src && (
                                        <TheoPlayerCustom
                                            key={`player-${index}`}
                                            state={replay.src === currentVideo?.src ? 'playing' : 'paused'}
                                            autoplay={replay.src === currentVideo?.src}
                                            src={replay.src || ''}
                                            type="application/x-mpegURL"
                                            className="h-full"
                                            controlsEnabled
                                        />
                                    )}
                                    <div className="max-md:hidden absolute bottom-[70px] left-0 w-full p-4 text-left typo-b1 regular md:pb-blockInner">
                                        {replay.description || ''}
                                    </div>
                                </div>

                                <div className="absolute bottom-[100px] transform left-0 md:left-[calc(50%+215px)] md:pb-blockInner w-full md:w-[calc(50%-215px)] flex items-center justify-between gap-4 pl-4 max-md:pr-4 max-md:pb-4 text-left">
                                    <div className="md:hidden typo-b1 regular max-md:w-[calc(100%-50px)]">
                                        {replay.description || ''}
                                    </div>
                                    {renderShareBtn()}
                                    {!isEnd ? (
                                        <div
                                            className="max-md:hidden"
                                            onClick={handleClickNextSlide}
                                        >
                                            <button className="bg-background-alt w-[50px] h-[50px] rounded-full grid place-items-center">
                                                <IonIcon icon={chevronDown} />
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    ) : null;
                })}
            </Swiper>
        </PageLayout>
    );
}
