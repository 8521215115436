import { IonCol, IonContent, IonGrid, IonHeader, IonModal, IonRow, IonToolbar, useIonToast } from '@ionic/react';
import Gallery from 'components/Gallery';
import Pill from 'components/Pill';
import AdComponent from 'components/Ads/AdComponent';
import { useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { Picture, PictureDetailResponse, PicturesReport as Report } from 'types';
import useResponsive from 'features/responsive/responsive';
import { useTranslation } from 'react-i18next';
import { isNativeAd } from 'lib/utils';
import { downloadImage } from 'pages/PicturesReport';
import LoadingIndicator from 'components/LoadingIndicator';
import { AdResponse } from 'features/advertising/generateAdPayload';

import { CloseIcon, DownloadIcon, LetzAiError, PersonIcon } from './Icons';
import WavePatternContainer from './WavePatternContainer';
import Img from './Img';
import ShareButton from './core/ShareButton';
import Title from './Title';

export interface PicturesReportGalleryProps {
    ads?:
        | AdResponse
        | {
              data: [];
          }
        | undefined;
    gallery: {
        status: 'error' | 'success' | 'loading';
        data: PictureDetailResponse | undefined;
    };
    isTagsEnabled?: boolean;
    isOrangeCornerEnabled?: boolean;
    isDownloadButtonEnabled?: boolean;
    doNotRedirectWhenEmpty?: boolean;
}

export default function PicturesReportGallerygrid({
    ads,
    gallery,
    isTagsEnabled = true,
    isOrangeCornerEnabled = true,
    isDownloadButtonEnabled = true,
    doNotRedirectWhenEmpty = false,
}: PicturesReportGalleryProps) {
    const { id: reportId } = useParams<{ id: string }>();

    const [selectedPicture, setSelectedPicture] = useState<Picture | undefined>(undefined);
    const { IsMobile, IsDesktop } = useResponsive();
    const [present] = useIonToast();
    const { t } = useTranslation();

    if (gallery?.status === 'error' && !doNotRedirectWhenEmpty) {
        return <Redirect to="/events/fotoen" />;
    }

    const renderPage = () =>
        gallery.status !== 'loading' ? (
            <>
                {gallery?.data?.title !== '' && <h2 className="typo-h1 font-bold">{gallery?.data?.title}</h2>}

                <WavePatternContainer
                    fixed="page"
                    onlyDesktop
                    position="bottom-right"
                    reverse
                >
                    {isTagsEnabled && (
                        <div className="flex mt-5">
                            <Pill>Pictures</Pill>
                        </div>
                    )}
                </WavePatternContainer>
                {gallery?.data?.description && (
                    <div className="flex items-center mt-4">
                        <PersonIcon />
                        <div className="ml-2 typo-b2 regular">{gallery?.data?.description}</div>
                    </div>
                )}

                <div className="mt-9 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-8">
                    {gallery?.data?.gallery && gallery?.data?.gallery?.length !== 0 ? (
                        gallery?.data?.gallery?.map((picture) =>
                            !isNativeAd(picture) ? (
                                <div className="md:transition-all md:duration-300 md:hover:scale-110 relative cursor-pointer">
                                    <Img
                                        key={picture.id}
                                        alt="gallery"
                                        src={picture.thumbnailUrl}
                                        onClick={() => setSelectedPicture(picture)}
                                        hasCardHoverEffect
                                        imgClassName="h-[150px] md:h-[310px] w-full"
                                        lazyLoading
                                    />
                                    {isOrangeCornerEnabled && (
                                        <div
                                            className={` ${
                                                IsMobile
                                                    ? 'picture-report-cut-corner-mobile'
                                                    : 'picture-report-cut-corner'
                                            }`}
                                        />
                                    )}
                                </div>
                            ) : (
                                picture && (
                                    <AdComponent
                                        ad={picture}
                                        hasSpacings={false}
                                        className="h-[220px] overflow-hidden"
                                    />
                                )
                            ),
                        )
                    ) : (
                        <article className="flex flex-col gap-[40px] col-span-3 mx-auto">
                            <Title
                                hidePicto
                                title={t('letzAi.noPhotosYet')}
                                className="text-center"
                            />
                            <LetzAiError />
                        </article>
                    )}
                </div>
            </>
        ) : (
            <LoadingIndicator />
        );

    return (
        <>
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}

                <IonRow className="">
                    <IonCol size-xs={12}>{renderPage()}</IonCol>
                </IonRow>
            </IonGrid>

            {gallery?.data?.gallery?.length && selectedPicture && (
                <IonModal
                    isOpen={!!selectedPicture}
                    onDidDismiss={() => setSelectedPicture(undefined)}
                    initialBreakpoint={1}
                    breakpoints={[0, 0.5, 0.25, 0.25]}
                    style={
                        IsDesktop
                            ? {
                                  '--max-width': '750px',
                                  '--width': '750px',
                                  '--backdrop-opacity': 0.6,
                                  '--border-radius': '15px',
                              }
                            : {}
                    }
                >
                    <IonHeader class="p-4">
                        <IonToolbar>
                            <div
                                slot="end"
                                className="flex gap-6 items-center justify-end m-2"
                            >
                                {!isNativeAd(selectedPicture) && (
                                    <div className="flex gap-1">
                                        <ShareButton url={selectedPicture?.originalUrl} />
                                        {isDownloadButtonEnabled && (
                                            <DownloadIcon
                                                onClick={() =>
                                                    downloadImage(
                                                        reportId,
                                                        selectedPicture,
                                                        present,
                                                        t('image.downloadSuccess'),
                                                        t('image.downloadError'),
                                                    )
                                                }
                                                className="cursor-pointer"
                                            />
                                        )}
                                    </div>
                                )}
                                <button
                                    onClick={() => {
                                        setSelectedPicture(undefined);
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent class="ion-padding p-10">
                        <Gallery
                            pictures={gallery?.data?.gallery ?? []}
                            currentPicture={selectedPicture}
                            setCurrentPicture={setSelectedPicture}
                        />
                    </IonContent>
                </IonModal>
            )}
            {ads && <AdComponent ad={ads.data[1]} />}
        </>
    );
}
