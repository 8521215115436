import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Title from 'components/Title';

interface LetzAiParticiaptedProps {
    onComplete: () => void;
}

export default function LetzAiParticiapted({ onComplete }: LetzAiParticiaptedProps) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-[40px] max-w-[550px] mx-auto">
            <section className="flex flex-col md:flex-row gap-[40px] items-center">
                <img
                    src="/assets/img/participated.webp"
                    alt="letz ai"
                    className="w-[150px] mx-auto"
                />
                <article className="flex flex-col gap-[40px] max-w-[350px]">
                    <Title
                        hidePicto
                        title={t('letzAi.participatedTitle')}
                    />
                    <p className="bold">{t('letzAi.participatedText')}</p>
                </article>
            </section>
            <div className="w-[270px] max-md:mx-auto">
                <Button
                    value={t('letzAi.backToEvent')}
                    className="!text-[#141414] bg-white hover:bg-[#e5e0e0] transition duration-300 border-0 mt-block-inner"
                    type="submit"
                    onClick={onComplete}
                />
            </div>
        </div>
    );
}
