import { MouseEventHandler } from 'react';
import AddIcon from '../../../../components/Icons/Add.svg';
import PlayIcon from '../../../../components/Icons/play.svg';
import PlayPrimaryIcon from '../../../../components/Icons/playPrimary.svg';
import StopIcon from '../../../../components/Icons/pause.svg';
import PauseIcon from '../../../../components/Icons/stop.svg';
import NextIcon from '../../../../components/Icons/chevron.svg';

type PlayerButtonType = 'play' | 'stop' | 'pause' | 'next' | 'previous' | 'playPrimary';

const icons = {
    play: PlayIcon,
    playPrimary: PlayPrimaryIcon,
    pause: PauseIcon,
    stop: StopIcon,
    next: NextIcon,
    previous: NextIcon,
    chevron: NextIcon,
};

const buttonsizes = {
    xxs: '35px',
    xs: '40px',
    sm: '45px',
};

const iconSizes = {
    xxs: '20px',
    xs: '25px',
    sm: '30px',
};

const getIconByType = (type: PlayerButtonType): string => {
    return icons[type] || AddIcon;
};

type PlayerButtonProps = {
    onClick: MouseEventHandler;
    type: PlayerButtonType;
    main?: boolean;
    size?: 'xxs' | 'xs' | 'sm';
    className?: string;
    animate?: boolean;
};

export default function PlayerButton({
    onClick,
    type,
    main,
    size = 'sm',
    className,
    animate = true,
}: PlayerButtonProps) {
    /*
        if a class is not seen during build, tailwind do not know that it has to be generated (concat class do not always work)
        workaround : do not use concateneted classNames or write all possible class in a comment (like bellow)
    */
    // h-[25px] w-[25px] h-[20px] w-[20px] h-[30px] w-[30px] h-[35px] w-[35px] h-[40px] w-[40px] h-[45px] w-[45px]

    return (
        <button
            type="button"
            onClick={onClick}
            className={`${className || ''} !bg-white rounded-full grid place-items-center 
            aspect-square
            transform transition-all
            ${animate ? 'hover:!bg-[rgba(255,255,255,0.9)] md:hover:scale-[110%]' : ''}
            w-[${buttonsizes[size]}] 
            h-[${buttonsizes[size]}] ${main ? `w-[60px] h-[60px] mt-[60px]` : ``}`}
        >
            {/* translate-x-[1px] because play icon seem not centered if at center */}
            <img
                className={`aspect-square h-[${iconSizes[size]}] w-[${iconSizes[size]}] m-2 ${
                    type === 'play' || type === 'playPrimary' ? 'transform translate-x-[1px]' : ''
                }`}
                src={getIconByType(type)}
                alt={type}
            />
        </button>
    );
}

PlayerButton.defaultProps = {
    main: false,
    size: 'sm',
};
