import { IonGrid } from '@ionic/react';
import PageLayout from 'components/PageLayout';
import TheoPlayerCustom from 'components/TheoplayerCustom';
import Title from 'components/Title';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAds from 'hooks/useAds';
import { ChromelessPlayer } from 'theoplayer';
import ShareButton from 'components/core/ShareButton';
import Section from 'components/core/Section';

export default function LiveStreamPage() {
    const queryParameters = new URLSearchParams(window.location.search);
    const link = queryParameters.get('link');

    const params = {
        src: link ? decodeURIComponent(link) : 'https://stream.rtl.lu/live/hls/eldotv',
        type: 'application/x-mpegurl',
    };
    const { ads } = useAds({ page: 'livestream' });

    const [player, setPlayer] = useState<undefined | ChromelessPlayer>();

    const { t } = useTranslation();

    useEffect(() => {
        if (!player) return;
        player.play();
    }, [player]);

    return (
        <PageLayout
            cssClass="eldo-page"
            title={t('live')}
        >
            <IonGrid fixed>
                <Section>
                    <div className="flex justify-between items-center pb-blockInner">
                        <Title
                            title={t('live')}
                            main
                            className="max-md:hidden"
                        />
                        <ShareButton url={params.src} />
                    </div>

                    <div className="w-full aspect-[16/9]">
                        {ads && (
                            <TheoPlayerCustom
                                init={setPlayer}
                                autoplay
                                src={params.src}
                                type={params.type}
                                adSrc={ads ? ads.data[0].url : ''}
                                className="h-full"
                                controls={{ fullscreen: true }}
                                controlsEnabled
                            />
                        )}
                    </div>
                </Section>
            </IonGrid>
        </PageLayout>
    );
}
