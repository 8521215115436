import { ToastOptions, isPlatform } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import PageLayout from 'components/PageLayout';
import useAds from 'hooks/useAds';
import { BASE_URL, getPicturesReport, handleResponse } from 'lib/ApiService';
import { useParams } from 'react-router';
import { Picture, PictureDetailResponse, PicturesReport as Report } from 'types';
import usePermutiveArticle from 'hooks/usePermutive';
import useResponsive from 'features/responsive/responsive';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import { Media, MediaAlbumResponse } from '@capacitor-community/media';
import PicturesReportGallerygrid from 'components/PicturesReportGallery';

import { TOAST_DURATION } from '../constants';

const downloadUrl = `${BASE_URL}/pictures-reports/download/`;

function downloadImageOnBrowser(
    reportId: string,
    selectedPicture: Picture | undefined,
    present: {
        (message: string, duration?: number | undefined): Promise<void>;
        (options: ToastOptions & HookOverlayOptions): Promise<void>;
    },
    successMessage: string,
    errorMessage: string,
) {
    fetch(`${downloadUrl}${reportId}/${selectedPicture?.id ?? ''}`)
        .then((response) => {
            handleResponse(response);
            return response.json();
        })
        .then(({ data }: { data: string }) => {
            // Create a download link
            const a = document.createElement('a');

            a.href = `data:image/jpg;base64,${data}`;
            a.download = `${selectedPicture?.id ?? 'eldo_picture'}.jpg`;

            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
        })
        .catch((error: Error) => present({ message: errorMessage, duration: TOAST_DURATION, color: 'danger' }));
}

async function downloadImageOnNativeApps(
    reportId: string,
    selectedPicture: Picture | undefined,
    present: {
        (message: string, duration?: number | undefined): Promise<void>;
        (options: ToastOptions & HookOverlayOptions): Promise<void>;
    },
    successMessage: string,
    errorMessage: string,
) {
    const response = await fetch(`${downloadUrl}${reportId}/${selectedPicture?.id ?? ''}`);
    handleResponse(response);

    const { data } = await response.json();

    // Generate a unique filename
    const fileName = `${selectedPicture?.id ?? 'eldo_picture'}.jpg`;
    if (isPlatform('android')) {
        await Media.getAlbums()
            .then(async (albumResponse: MediaAlbumResponse) => {
                await Media.savePhoto({
                    path: `data:image/jpg;base64,${data as string}`,
                    albumIdentifier: albumResponse.albums[0].identifier,
                })
                    .then(() => {
                        present({ message: successMessage, duration: TOAST_DURATION, color: 'success' });
                    })
                    .catch(() => {
                        present({ message: errorMessage, duration: TOAST_DURATION, color: 'danger' });
                    });
            })
            .catch(() => {
                present({ message: errorMessage, duration: TOAST_DURATION, color: 'danger' });
            });
    } else {
        await Media.savePhoto({ path: `data:image/jpg;base64,${data as string}` })
            .then(() => {
                present({ message: successMessage, duration: TOAST_DURATION, color: 'success' });
            })
            .catch(() => {
                present({ message: errorMessage, duration: TOAST_DURATION, color: 'danger' });
            });
    }
}

export function downloadImage(
    reportId: string,
    selectedPicture: Picture | undefined,
    present: {
        (message: string, duration?: number | undefined): Promise<void>;
        (options: ToastOptions & HookOverlayOptions): Promise<void>;
    },
    successMessage: string,
    errorMessage: string,
) {
    return isPlatform('cordova')
        ? downloadImageOnNativeApps(reportId, selectedPicture, present, successMessage, errorMessage)
        : downloadImageOnBrowser(reportId, selectedPicture, present, successMessage, errorMessage);
}

export default function PicturesReport() {
    const { id: reportId } = useParams<{ id: string }>();
    const { ads } = useAds({ page: 'report-detail', articleId: reportId });
    usePermutiveArticle({
        audioContent: false,
        videoContent: false,
        photoGallery: true,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: [],
        wordCount: 0,
    });
    const { IsMobile } = useResponsive();

    const { data, status } = useQuery<PictureDetailResponse>(['picture-report', reportId], () =>
        getPicturesReport(reportId),
    );

    return (
        <PageLayout
            title={data?.title}
            hasBackButton
            backPath="/events/fotoen"
        >
            <meta
                property="og:type"
                content="website"
            />
            <meta
                property="og:title"
                content="Eldo"
            />
            <meta
                property="og:description"
                content={data?.title || 'Den Hit Radio zu letzebuerg'}
            />
            <meta
                property="og:image"
                content={data?.gallery[0].thumbnailUrl || '/assets/img/Welcome_Chiko.png'}
            />
            <PicturesReportGallerygrid
                ads={ads}
                gallery={{ data, status }}
            />
        </PageLayout>
    );
}
