import { useContext } from 'react';
import { IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import useResponsive from 'features/responsive/responsive';
import { useHistory } from 'react-router';
import PlayerButton from 'features/radio/component/player/PlayerButton';
import { PlayerContext, PlayerContextType } from 'features/radio/PlayerContext';
import Img from './Img';

export type MobileHeaderProps = {
    title?: string;
    hasLogo?: boolean;
    hasBillboard?: boolean;
    hasBackButton?: boolean;
    backPath?: string;
    reduceBottomSpace?: boolean;
};

export default function MobileHeader({
    hasBillboard,
    hasBackButton,
    hasLogo,
    title,
    reduceBottomSpace,
    backPath,
}: MobileHeaderProps) {
    const { IsDesktop } = useResponsive();
    const { playerState, playerDispatch } = useContext(PlayerContext) as PlayerContextType;
    const history = useHistory();

    const customBackHandler = () => {
        // For example, you can conditionally navigate based on certain conditions
        if (backPath) {
            history.push(backPath); // Navigate to a custom route
        } else {
            history.goBack(); // Default behavior, goes back to the previous page
        }
    };

    return (
        <IonHeader className="mobile-header page-header">
            <IonToolbar className={`flex ${reduceBottomSpace ? '-mb-[25px]' : ''}`}>
                {hasBackButton && (
                    <IonButtons
                        slot="start"
                        onClick={customBackHandler}
                    >
                        <IonIcon
                            icon={chevronBackOutline}
                            className="w-[24px] h-[24px]"
                        />
                    </IonButtons>
                )}
                {hasLogo && (
                    <div className="flex justify-center relative">
                        <div className="flex">
                            <Img
                                imgClassName="h-[65px]"
                                src="/assets/img/logo.png"
                                alt="logo"
                            />
                            <PlayerButton
                                size="xs"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    playerDispatch({ type: 'TOGGLE_RADIO' });
                                }}
                                className="absolute right-4 top-3"
                                type={playerState.isPlaying && playerState.type === 'RADIO' ? 'pause' : 'playPrimary'}
                            />
                        </div>
                    </div>
                )}

                {title && <IonTitle className="text-center typo-b1 bold">{title}</IonTitle>}
            </IonToolbar>
        </IonHeader>
    );
}

MobileHeader.defaultProps = {
    hasBillboard: false,
    hasBackButton: false,
    hasLogo: false,
    title: '',
};
