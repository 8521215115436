import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { Article, ArticlePart, Picture, PicturesReport } from 'types';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import usePermutiveArticle from 'hooks/usePermutive';
import AdComponent from 'components/Ads/AdComponent';
import useActionArticle from 'features/actions/hooks/useActionArticle';
import ButtonGroup from 'components/ButtonGroup';
import CardListingChunksWithAds from 'components/CardListingChunksWithAds';
import CardVertical from 'components/core/CardVertical';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { ipAktiounen } from 'features/radio/constants';
import NewsInfiniteScroll from 'components/NewsInfiniteScroll';
import { isTopicRoute } from 'lib/utils';
import LetzAi from 'components/letzAi/LetzAi';
import PicturesReportGallery from 'components/PicturesReportGallery';
import usePictureReportsGallery from 'features/pictureReport/hooks/usePicturesReportGallery';
import ArticlePageComponent, {
    getCopyrightForArticle,
    getThumbnail,
    getThumbnailForHorizontalArticleList,
} from '../components/core/ArticlePageComponent';
import { ArticlePartComponent } from './NewsArticle';
import { getCopyrightForAction } from './ActionsPage';

export default function ActionsArticlePage({ isCompactView }: NewsArticleProps) {
    const [chosenTabByHash, setChosenTabByHash] = useState<any>(undefined);
    const [currentArticleForList, setCurrentArticleForList] = useState<string | undefined>(undefined);
    const [currentTopicCategory, setCurrentTopicCategory] = useState<string | undefined>(undefined);

    const { IsMobile } = useResponsive();
    const { slug } = useParams<{ slug: string }>();
    const eventNavigatorSlug = 'eldo-event-navigator';
    const { data, isLoading } = useActionArticle(slug || eventNavigatorSlug);
    const history = useHistory();

    const [currentTab, setCurrentTab] = useState<number>(0);
    const { ads } = useAds({
        page: 'action-detail',
        reloadOnChangeString: currentTab.toString(),
        tags: isTopicRoute() ? undefined : [{ publicTag: 'IPAktiounen', name: 'IPAktiounen', tag: 'IPAktiounen' }],
        isLoading,
    });
    const { data: pictureReport, status } = usePictureReportsGallery(slug);

    const contents = { audio: false, video: false, photoGallery: false };
    const totalWords: number = Array.isArray(data?.tabs)
        ? data?.tabs.reduce((totalTabWords: number, tab) => {
              const tabWordCount: number =
                  (!Array.isArray(tab?.content) &&
                      tab?.content?.parts?.reduce((total: number, part: ArticlePart | undefined) => {
                          const wordCount =
                              part?.type === 'paragraph'
                                  ? part?.content?.trim().replace(/\s+/g, ' ').split(' ').length || 0
                                  : 0;

                          if (part?.type === 'attachment') {
                              contents.video = contents.video || part.data?.type === 'embed';
                              contents.audio = contents.audio || part.data?.type === 'audio';
                          }

                          return total + wordCount;
                      }, 0)) ||
                  0;

              return totalTabWords + tabWordCount;
          }, 0) || 0
        : 0;

    // we could call this again when the chosen tab has been changed
    usePermutiveArticle({
        audioContent: contents.audio,
        videoContent: contents.video,
        photoGallery: contents.photoGallery,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: [],
        wordCount: totalWords,
        send: !isLoading,
    });

    const preSelectArticle = () => {
        data?.tabs.forEach((tab, index) => {
            if (decodeURIComponent(window.location.hash.substring(1)) === tab.title && tab.title !== '') {
                setCurrentTab(index);
                setChosenTabByHash({ name: tab.title, id: tab.id });
            } else if (
                decodeURIComponent(window.location.hash.substring(1)).includes(tab.title ?? '') &&
                tab.title !== ''
            ) {
                const parts = decodeURIComponent(window.location.hash.substring(1)).split('--');

                const tabName = parts[0];
                const articleId = parts[parts.length - 1];

                setCurrentTab(index);
                setChosenTabByHash({ name: tabName, id: tab.id });
                setCurrentArticleForList(articleId);
            }
        });
    };

    const triggerCategoryListToLoad = () => {
        if (isTopicRoute() && data?.tabs[currentTab].type === 'listing') {
            const listingTab = data?.tabs[currentTab]?.content;
            if (Array.isArray(listingTab)) {
                setCurrentTopicCategory(listingTab[0]?.category_path);
            }
        }
    };

    useEffect(() => {
        if (data) {
            preSelectArticle();
            triggerCategoryListToLoad();
        }
    }, [data]);

    useEffect(() => {
        triggerCategoryListToLoad();
    }, [currentTab]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [data, ads]);

    // @ts-ignore
    const currentParts =
        currentArticleForList && Array.isArray(data?.tabs[currentTab]?.content)
            ? // @ts-ignore
              data?.tabs[currentTab]?.content?.find((article: Article) => article.id === currentArticleForList)?.parts
            : // @ts-ignore
              data?.tabs[currentTab]?.content?.parts;
    const currentLists = data?.tabs[currentTab]?.content;

    const displayActionArticleOrActionList = () => {
        return !Array.isArray(currentLists) || currentArticleForList
            ? (currentParts || [])?.map((part: any) => {
                  const adUrl =
                      ads && ads?.data.length
                          ? ads?.data?.filter((item) => item.container === 'media_ads')[0]?.url
                          : '';
                  const updatedPart = { ...part, adUrl };
                  return ads && !isLoading && currentParts ? (
                      <ArticlePartComponent
                          part={updatedPart}
                          IsMobile={IsMobile}
                          displayGalleryCopyright
                      />
                  ) : (
                      ''
                  );
              })
            : [
                  !isTopicRoute() ? (
                      <CardListingChunksWithAds
                          adsBetween={ads ? [ads.data[4]] : []}
                          className="mb-blockMobile md:mb-block mt-blockMobile md:mt-block"
                          data={currentLists || []}
                          adNativeClassName="!h-[175px] overflow-hidden"
                      >
                          {(m) => (
                              <div
                                  onClick={() => {
                                      if (isTopicRoute()) {
                                          history.push(
                                              `${
                                                  data?.tabs[currentTab].path
                                                      ? `/radio/${data?.tabs[currentTab].path as unknown as string}/a`
                                                      : '/aktuell/news/a'
                                              }/${(m.id as unknown as string) ?? ''}`,
                                          );
                                      } else {
                                          window.location.hash = `${data?.tabs[currentTab]?.title ?? ''}--article--${
                                              (m.id as unknown as string) ?? ''
                                          }`;
                                          setCurrentArticleForList(m.id);
                                      }
                                  }}
                                  key={m.id}
                                  className={`${isTopicRoute() ? 'h-[175px]' : 'h-[340px]'} w-full`}
                              >
                                  {!isTopicRoute() ? (
                                      <CardVertical
                                          className="w-full h-full pb-4"
                                          title={m.title}
                                          // @ts-ignore
                                          thumbnailUrl={getThumbnail(m, [
                                              'articleONLY',
                                              'focus',
                                              'listing_aktiounen',
                                              'articleANDListing',
                                          ])}
                                          imgSize="small"
                                          hasWavePattern={false}
                                          titleTruncated={3}
                                      />
                                  ) : (
                                      <HorizontalMediumCard
                                          title={m.title ?? ''}
                                          date={m.date}
                                          tags={m.tags ?? []}
                                          thumbnailUrl={getThumbnailForHorizontalArticleList(m as Article)}
                                          truncated={3}
                                          onClickForPill={() =>
                                              history.push(
                                                  data?.tabs[currentTab].path
                                                      ? `/radio/${data?.tabs[currentTab].path as unknown as string}/`
                                                      : '/aktuell/news/',
                                              )
                                          }
                                          imageCover={m.header?.listing_news ? 'object-fit' : 'object-cover'}
                                      />
                                  )}
                              </div>
                          )}
                      </CardListingChunksWithAds>
                  ) : (
                      <NewsInfiniteScroll
                          categoryId={parseInt(currentTopicCategory ?? '0', 10)}
                          ads={ads}
                          adPosition={4}
                      />
                  ),
              ];
    };

    function displayLetzAiContent() {
        if (['gallery', 'galerie', 'galery'].includes(data?.tabs[currentTab]?.title?.toLowerCase().trim() ?? '')) {
            return (
                <PicturesReportGallery
                    isDownloadButtonEnabled={false}
                    isOrangeCornerEnabled={false}
                    isTagsEnabled={false}
                    ads={ads}
                    doNotRedirectWhenEmpty
                    gallery={{
                        data: {
                            ...pictureReport,
                            gallery: pictureReport?.gallery?.map((picture: Picture) => ({
                                thumbnailUrl: picture.originalUrl,
                                ...picture,
                            })) as Picture[],
                        },
                        status,
                    }}
                />
            );
        }
        if (
            ['participant', 'maach mat', 'participate'].includes(
                data?.tabs[currentTab]?.title?.toLowerCase().trim() ?? '',
            )
        ) {
            return <LetzAi additionalData={{ ...data?.additionalData, slug: data?.slug ?? '' }} />;
        }
        return displayActionArticleOrActionList();
    }

    return (
        <ArticlePageComponent
            isLoading={isLoading}
            shareBtnProps={{ url: `${process.env.REACT_APP_FRONTEND_URL || ''}${window.location.pathname}` }}
            description={data?.meta[0].type === 'letz_ai' ? displayLetzAiContent() : displayActionArticleOrActionList()}
            isLetzAi={data?.meta[0].type === 'letz_ai'}
            inpageAdUrl={
                ads &&
                ((Array.isArray(ads.data) ? ads?.data : null) || [])?.filter((item) => item.container === 'inpage')[0]
                    ?.url
            }
            title={data?.title || ''}
            image={{
                src: currentArticleForList
                    ? getThumbnail(
                          // @ts-ignore
                          data?.tabs[currentTab]?.content?.find((article) => article.id === currentArticleForList),
                          ['articleONLY', 'focus', 'listing_aktiounen', 'articleANDListing'],
                      )
                    : data?.tabs[currentTab]?.image || '',
                alt: 'Image',
            }}
            slotStart={
                ads && (
                    <AdComponent
                        removeMarginTopOnWFull
                        ad={ads.data[0]}
                        className={`${
                            IsMobile ? '!-mx-[calc(var(--padding-start)+5px)] !w-screen flex justify-center' : ''
                        }`}
                    />
                )
            }
            slotImageCaption={
                currentArticleForList
                    ? getCopyrightForArticle(
                          // @ts-ignore
                          data?.tabs[currentTab]?.content?.find((article) => article.id === currentArticleForList),
                      )
                    : getCopyrightForAction(data) ?? ''
            }
            imgOpacityGradient={false}
            tagsWithLinkProps={[]}
            tags={isTopicRoute() ? [] : [ipAktiounen]}
            slotEndFirstSection={
                <>
                    {ads && IsMobile && <AdComponent ad={ads.data[1]} />}
                    {!isLoading && (
                        <ButtonGroup
                            onClick={() => setCurrentArticleForList(undefined)}
                            className="mt-block-innerMobile md:mt-block-inner"
                            value={chosenTabByHash}
                            onChange={(opt) => {
                                // @ts-ignore
                                setCurrentTab(opt.index);
                            }}
                            eventNavigator={data?.slug === eventNavigatorSlug}
                            options={
                                data?.tabs
                                    .map((tab, index) => {
                                        return { name: tab.title ?? '', id: tab.id, index };
                                    })
                                    ?.filter((filteredTab) => filteredTab.id !== '' || filteredTab.name !== '') || []
                            }
                        />
                    )}
                </>
            }
            slotEnd={<div>{ads && <AdComponent ad={ads.data[5]} />}</div>}
        />
    );
}

type NewsArticleProps = {
    isCompactView?: boolean;
};

ActionsArticlePage.defaultProps = {
    isCompactView: false,
};
