import GridLoader from 'react-spinners/GridLoader';
import PuffLoader from 'react-spinners/PuffLoader';

export default function LoadingIndicator({ imageLoader }: { imageLoader?: boolean }) {
    return (
        <div className="w-[60px] mx-auto mt-block-innerMobile md:mt-block-inner mb-blockInnerMobile md:mb-blockInner">
            {!imageLoader ? (
                <GridLoader
                    className="flex self-center"
                    color="#f08b1f"
                    loading
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            ) : (
                <div className="flex self-center">
                    <PuffLoader
                        className="flex self-center"
                        color="#f08b1f"
                        loading
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
        </div>
    );
}
