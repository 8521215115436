import { IonCol, IonFab, IonGrid, IonIcon } from '@ionic/react';
import OrnamentBlue from 'components/Ornament_blue';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import { useTranslation } from 'react-i18next';
import ShareButton, { ShareButtonProps } from 'components/core/ShareButton';
import Section from 'components/core/Section';
import LoadingIndicator from 'components/LoadingIndicator';
import Pill from 'components/Pill';
import { Link, type LinkProps } from 'react-router-dom';
import Divider from 'components/core/Divider';
import FullContentHeight from 'components/FullContentHeight';
import TheoPlayerCustom from 'components/TheoplayerCustom';
import InPage from 'components/Ads/Inpage';
import { ActionList, Article, Tags } from 'types';

export type ArticlePageComponentProps = {
    image?: {
        src: string;
        alt: string;
        className?: string;
    };
    video?: {
        src: string;
        adSrc?: string;
        img?: string;
    };
    inpageAdUrl?: string;
    title: string;
    kicker?: string;
    description: React.ReactNode[] | string[];
    shareBtnProps?: ShareButtonProps;
    tagsWithLinkProps: Array<{ name: string; linkProps: LinkProps }> | undefined;
    tags: Tags[];
    imgOpacityGradient?: boolean;
    ornamentBlueMobileOverlap?: boolean;
    pageClassName?: string;
    imgOrVideoSectionClassName?: string;

    slotRightTags?: React.ReactNode;
    slotStart?: React.ReactNode;
    slotEndFirstSection?: React.ReactNode;
    slotEnd?: React.ReactNode;
    slotDescriptionRight?: React.ReactNode;
    slotImageCaption?: React.ReactNode;
    slotPodcastArticle?: React.ReactNode;
    isLoading: boolean;
    isLetzAi?: boolean;
};

export function getThumbnail(article: Article | ActionList | undefined, types: string[]) {
    if (article) {
        return (
            types
                // @ts-ignore
                .map((type) => article.header?.[type]?.thumbnailUrl)
                .find((thumbnailUrl) => thumbnailUrl && thumbnailUrl !== '') || ''
        );
    }
    return '';
}

export function getCopyright(article: Article | ActionList | undefined, types: string[]) {
    if (article) {
        return (
            // @ts-ignore
            types.map((type) => article.header?.[type]?.copyright).find((copyright) => copyright && copyright !== '') ||
            ''
        );
    }
    return '';
}

export function getThumbnailForArticle(article: Article | undefined) {
    const types = ['articleANDListing', 'articleOnly'];
    return getThumbnail(article, types);
}

export function getCopyrightForArticle(article: Article | undefined) {
    const types = ['articleANDListing', 'articleOnly'];
    return getCopyright(article, types);
}

export function getThumbnailForHorizontalArticleList(article: Article | undefined) {
    const types = ['listing_news', 'articleANDListing', 'listingONLY'];
    return getThumbnail(article, types);
}

export function getThumbnailForFilmSortien(article: Article | undefined) {
    const types = ['articleANDListing', 'listing_news', 'listingONLY'];
    return getThumbnail(article, types);
}

export default function ArticlePageComponent({
    slotStart,
    slotEndFirstSection,
    slotEnd,
    slotRightTags,
    slotDescriptionRight,
    slotImageCaption,
    slotPodcastArticle,
    image,
    video,
    inpageAdUrl,
    title,
    kicker,
    description,
    tagsWithLinkProps,
    tags,
    imgOpacityGradient = true,
    ornamentBlueMobileOverlap = true,
    pageClassName,
    imgOrVideoSectionClassName,
    shareBtnProps,
    isLoading,
    isLetzAi,
}: ArticlePageComponentProps) {
    const { IsMobile, IsDesktop } = useResponsive();

    return (
        <PageLayout
            title=""
            cssClass={pageClassName}
            hasBackButton
            tags={tags}
        >
            <meta
                property="og:type"
                content="website"
            />
            <meta
                property="og:title"
                content="Eldo"
            />
            <meta
                property="og:description"
                content={title || 'Den Hit Radio zu letzebuerg'}
            />
            <meta
                property="og:image"
                content={image?.src || video?.img || '/assets/img/Welcome_Chiko.png'}
            />
            {!isLoading ? (
                <div>
                    <IonGrid fixed>
                        <IonCol>{slotStart || ''}</IonCol>
                        <Section>
                            {video?.src?.length || image?.src?.length ? (
                                <div
                                    className={`relative ${imgOrVideoSectionClassName || ''} ${
                                        IsMobile
                                            ? `full-width !max-w-auto m-auto flex align-center flex-col justify-center ${
                                                  ornamentBlueMobileOverlap ? 'pb-[32px]' : 'overflow-hidden'
                                              }`
                                            : 'm-auto flex align-center flex-col justify-center w-fit'
                                    }`}
                                >
                                    {image != null ? (
                                        <img
                                            src={image.src}
                                            alt={image.alt}
                                            className="md:max-w-[730px] md:min-w-[550px]"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {video != null ? (
                                        <FullContentHeight
                                            style={(height) => ({ height: `${height - 60}px` })}
                                            className="flex w-full justify-center"
                                        >
                                            {(height) => (
                                                <div
                                                    style={{
                                                        height: `${height - 60}px`,
                                                        width: `${(height - 60) / 1.8}px`,
                                                    }}
                                                >
                                                    <TheoPlayerCustom
                                                        className="h-full"
                                                        src={video?.src ?? ''}
                                                        type="application/x-mpegURL"
                                                        autoplay
                                                        adSrc={video.adSrc}
                                                        controlsEnabled
                                                    />
                                                </div>
                                            )}
                                        </FullContentHeight>
                                    ) : (
                                        ''
                                    )}

                                    {!video && imgOpacityGradient ? (
                                        <div
                                            className="absolute top-0 left-0 w-full h-full"
                                            style={{
                                                background:
                                                    'linear-gradient(180deg, rgba(27, 27, 27, 0) 0%, rgba(27, 27, 27, 0.58) 48.95%, #141414 100%)',
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    {IsMobile && (
                                        <OrnamentBlue
                                            className={
                                                ornamentBlueMobileOverlap
                                                    ? 'left-[0px] top-[-32px]'
                                                    : 'left-[0px] top-[-36px]'
                                            }
                                        />
                                    )}
                                    {slotImageCaption ? (
                                        <div
                                            className={`z-20 text-gray-400 italic text-[16px] ${
                                                IsMobile ? 'text-right pr-2' : ''
                                            }`}
                                        >
                                            © {slotImageCaption}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="flex justify-between items-center pt-blockInnerMobile md:pt-blockInner relative">
                                <div>
                                    {title !== '' ? <h2 className="typo-h1 font-bold">{title}</h2> : ''}
                                    {kicker !== '' ? <h2 className="typo-h3">{kicker}</h2> : ''}
                                </div>
                                {shareBtnProps != null ? (
                                    <ShareButton
                                        {...shareBtnProps}
                                        className="absolute -right-2 -top-8"
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="flex flex-wrap gap-[15px] my-blockInnerMobile md:my-blockInner">
                                    {tagsWithLinkProps?.map(({ name, linkProps }) => (
                                        <Link
                                            key={name}
                                            {...linkProps}
                                        >
                                            <Pill key={name}> {name} </Pill>
                                        </Link>
                                    ))}
                                </div>
                                <div>{slotRightTags || ''}</div>
                            </div>
                            {slotEndFirstSection || ''}
                        </Section>
                        {!isLetzAi && <Divider />}
                        <Section>
                            <div className="flex gap-4">
                                <div className="article-description flex-1 grid gap-4 h-fit">{description}</div>
                                {IsDesktop && slotDescriptionRight != null ? (
                                    <div className="min-w-[0]">{slotDescriptionRight || ''}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            {IsMobile && slotDescriptionRight != null ? (
                                <div className="min-w-[300px]">{slotDescriptionRight || ''}</div>
                            ) : (
                                ''
                            )}
                        </Section>
                        <Divider />
                        {slotPodcastArticle}
                        {inpageAdUrl && inpageAdUrl !== '' && (
                            <InPage
                                adSrc={inpageAdUrl}
                                nameOfElementId="articleInpage"
                            />
                        )}
                        {slotEnd}
                    </IonGrid>
                </div>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}
