import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { LetzAiError as LetzAiErrorIcon } from 'components/Icons';

interface LetzAiErrorProps {
    onComplete: () => void;
}

export default function LetzAiError({ onComplete }: LetzAiErrorProps) {
    const { t } = useTranslation();

    return (
        <div>
            <div className="md:mt-12.5 h-fit grid place-items-center max-md:absolute max-md:bottom-12.5">
                <LetzAiErrorIcon className="mx-auto relative" />
                <div className="typo-h2 bold">{t('letzAi.letzAiError')}</div>
                <div className="w-fit mt-7.5 flex justify-center">
                    <Button
                        onClick={onComplete}
                        value={t('letzAi.startAgain')}
                        className="bg-transparent text-white md:w-[350px] w-full"
                    />
                </div>
            </div>
        </div>
    );
}
