import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MoonEldoIcon } from './Icons';

export interface TitleProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    path?: string;
    main?: boolean;
    direction?: 'none' | 'forward' | 'back' | 'root' | undefined;
    hidePicto?: boolean;
}

export default function Title({
    title,
    path,
    main = false,
    direction = 'forward',
    className = '',
    children,
    hidePicto,
    ...rest
}: TitleProps) {
    const { t } = useTranslation();

    return (
        <div
            className={`flex items-center justify-between ${className}`}
            {...rest}
        >
            <h3 className={`${main ? 'typo-h1 bold' : 'typo-h2 bold'} flex items-center mb-2 w-full`}>
                {!hidePicto ? (
                    <div>
                        <MoonEldoIcon className="text-success" />
                    </div>
                ) : (
                    ''
                )}
                <div className={clsx(!hidePicto && 'ml-4', 'w-full')}>{title}</div>
            </h3>
            {children}

            {path && (
                /* transform translate-x-[4px]: because icon "chevronForwardOutline" have inner spacing (not css) */
                <IonButtons
                    slot="primary"
                    className="transform translate-x-[4px]"
                >
                    <IonButton
                        className="flex justify-end items-center text-primary group eldo-title-link-btn"
                        routerLink={path}
                        routerDirection={direction}
                    >
                        <span className="hidden  md:group-hover:block !text-white !normal-case mr-1">
                            {t('view_all')}
                        </span>
                        <IonIcon
                            icon={chevronForwardOutline}
                            color="secondary"
                        />
                    </IonButton>
                </IonButtons>
            )}
        </div>
    );
}
